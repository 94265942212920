<template>
    <el-dialog title="Task" :visible.sync="showPopup" class="my-dialog-class"  :close-on-click-modal="false">
        
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
        <el-row :gutter="15" >
            <el-col :md='12'>
                <el-form-item label='Client' >
                    <el-select size="mini" filterable v-model="selectedObject.IdClient" :disabled="readonly_client" placeholder="Select" class="full-width" @change="on_change_client">
                        <el-option v-for="c in Info.clienti" :key="'client-' + c.Id" :label="c.Nume" :value="c.Id"/>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md='12'>
                <el-form-item label='Locatii' >
                    <el-select size="mini"  v-model="selectedObject.IdLocatie" :disabled="readonly_client" placeholder="Select" class="full-width">
                        <el-option v-for="l in Info.locatii" :key="'locatie-' + l.Id" :label="l.Nume" :value="l.Id"/>
                    </el-select>
                </el-form-item>
            </el-col>
            
            <el-col :md='12'>
                <el-form-item label='Termen finalizare' >
                    <span v-if="!is_mobile()">
                        <el-date-picker size="mini"  v-model="selectedObject.TermenFinalizare" 
                            class="full-width"
                            value-format='yyyy-MM-dd HH:mm:ss' 
                            format='dd.MM.yyyy HH:mm' 
                            type="datetime" placeholder="Termen finalizare" 
                            :picker-options="DatePickerOptions"
                        />
                    </span>
                    <span v-if="is_mobile()">
                        <el-row :gutter="15">
                            <el-col :span="12">
                                <input type="date" class="el-input__inner " v-model="TermenFinalizare_Mobile.Date" />
                            </el-col>
                            <el-col :span="12">
                                <input type="time" class="el-input__inner " v-model="TermenFinalizare_Mobile.Time" />
                            </el-col>
                        </el-row>
                    </span>
                </el-form-item>
            </el-col>

            <el-col :md='12'>
                <el-form-item label='Alocat lui' >
                    <el-select  size="mini" v-model="selectedObject.IdUserAlocare" placeholder="Select" class="full-width">
                        <el-option v-for="u in Info.utilizatori" :key="'user-' + u.Id" :label="u.Nume" :value="u.Id"/>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md='24'>
                <el-form-item label='Descriere task' >
                    <el-input  size="mini" class='full-width' v-model='selectedObject.Mesaj' type='textarea' :rows="5" />
                </el-form-item>
            </el-col>
        
        </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer" >
        <el-button @click="showPopup=false"     > Renunta  </el-button>
        <el-button type="primary" @click="save" > Salveaza </el-button>
    </span>
</el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage';
import moment from 'moment';


export default {
    name: "Locatii_dialog",
    extends: BasePage,
    data () {
        return {
            baseUrl :'',
            showPopup: false,
            id_locatie: '-1',
            TermenFinalizare_Mobile: {
                Date: moment().format("YYYY-MM-DD"),
                Time: moment().format("HH:mm"),
                getDate(){
                    return this.Date + " " + this.Time;
                }
            },
            mode: 'add',
            Info:{
                my_id: '-1',
                id_client: '-1',
                utilizatori: [], 
                clienti: [],
                locatii: [],
            },
            readonly_client: false,
            DatePickerOptions: {},
            selectedObject: { 
                IdLocatie: '-1',
                IdUser: '-1',
                IdClient: '-1',
                IdUserAlocare: '-1',
                DataCreare: '',
                TermenFinalizare: '',
                Mesaj: '',
            },
            rules: {
                Mesaj: [ { required: true, message: 'Campul este obligatoriu' } ], 
            }
        }
    },
    methods: {
        show_me: async function( mode, id_locatie, id_task, readonly_client=true ) {
            this.showPopup        = true;
            this.id_locatie       = id_locatie;
            this.readonly_client  = readonly_client;
            
            
            await this.get_info();
            this.selectedObject.IdClient = this.Info.id_client;

            await this.on_change_client();

            if( mode == "add" )
            {
                this.mode = "add";
                this.selectedObject.DataCreare          = moment().format("YYYY-MM-DD HH:mm:ss");
                this.selectedObject.TermenFinalizare    = moment().format("YYYY-MM-DD HH:mm:ss");
                if( id_locatie == -1 ){
                    var id_client_selectat      = settings.get_id_client_selectat();
                    if( id_client_selectat != null && id_client_selectat != '' ){
                        this.selectedObject.IdClient = id_client_selectat;
                    } else{
                        this.selectedObject.IdClient = this.Info.clienti[0].Id;
                    }
                    
                    
                    await this.on_change_client();
                    id_locatie = this.Info.locatii[0].Id;
                }
                this.selectedObject.IdLocatie           = id_locatie;
                this.selectedObject.IdUserAlocare       = this.Info.my_id;
                
                this.selectedObject.Mesaj               = '';
            }
            else
            {
                this.mode             = "edit";
                var            result = await this.post("locatii/get_info_item_dialog_task", { id: id_task } );
                this.selectedObject   = result.Item;        
            }
        },

        is_mobile() {
            return window.innerWidth < 1024;
        },

        async get_info() {
            this.DatePickerOptions    = settings.GlobalDatePickerOptions_DoarViitor;
            var result  = await this.post( "locatii/get_info_taskuri", { id_locatie: this.id_locatie } );
            this.Info   = result;
        },

        async on_change_client() {
            var result          = await this.post( "locatii/get_locatii_client", { IdClient: this.selectedObject.IdClient } );
            this.Info.locatii   = result.locatii;

            var existing = this.Info.locatii.filter( x=> x.Id == this.selectedObject.IdLocatie );
            if( existing.length > 0 ){
                this.selectedObject.IdLocatie = existing[0].Id;
            }else{
                if( this.Info.locatii.length > 0 )
                    this.selectedObject.IdLocatie = this.Info.locatii[0].Id;
            }
        },

        save: async function() {
            if( this.is_mobile() ){
                if( this.TermenFinalizare_Mobile.Date.trim() == "" ){
                    this.$message.error('Data nu poate fi goala!');
                    return;
                }
                if( this.TermenFinalizare_Mobile.Time.trim() == "" ){
                    this.$message.error('Ora nu poate fi goala!');
                    return;
                }
                
                this.selectedObject.TermenFinalizare = this.TermenFinalizare_Mobile.getDate();
            }

            if( this.selectedObject.Mesaj.trim() == "" ){
                this.$message.error('Mesajul nu poate fi gol!');
                return;
            }
            
            

            this.$refs['my-form'].validate( async(valid) => {
                if (valid)
                {   
                    await this.post("locatii/save_task", { mode: this.mode, object: this.selectedObject } );
                    this.showPopup = false;
                    this.$emit("save");
                }
            });
        }
    },
    mounted: function() {
        this.baseUrl = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>
.full-width {
    width: 100%;
}

</style>